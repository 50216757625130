import React from 'react';

import './WebsiteList.scss';
import { ListArrow } from '../../Atoms/Icons/Icons';
import useMedias from '../../hooks/useMedias';
import { trackPlaquetteEvent } from '../../Utils/Tracking';

let classNames = require('classnames');

const WebsiteList = ({ componentData, imagesArray, alignment, page_name, page_type, activity }) => {
  const { processUrl, getImage } = useMedias();

  const firstSite = componentData.field_sites_web[0];

  let blocVisuel = false;

  if (firstSite.relationships?.field_image?.drupal_internal__mid != null)
    blocVisuel = true;

  const webSiteList = blocVisuel
    ? componentData.field_sites_web.filter((_, i) => i > 0)
    : componentData.field_sites_web;

  return (
    <div className="websiteList">
      {/* ${alignment === 'right' ? 'alignLeft' : 'alignRight'} */}

      <div className={'websiteListTitle'}>
        <div dangerouslySetInnerHTML={{ __html: componentData?.legend }} />
      </div>

      <div
        className={
          blocVisuel ? 'websiteListDetail has_visuel' : 'websiteListDetail'
        }
      >
        {blocVisuel && (
          <div className={classNames('listVisuel')}>
            <a target="_blank" href={firstSite?.field_link?.url} onClick={() => trackPlaquetteEvent({
              'event': 'clic_plaquette',
              'page_name': page_name,
              'activity': activity,
              'content_name': firstSite?.field_subtitle,
              'page_type': page_type,
              'external_link_url': firstSite?.field_link?.url?.indexOf('.solutions-colas') > 0 ? firstSite?.field_link?.url : '/',
              'external_link_text': firstSite?.field_link?.url?.indexOf('.solutions-colas') > 0 ? 'site_solutions' : ''
            })}>
              <img
                src={processUrl(
                  getImage(
                    imagesArray,
                    firstSite.relationships?.field_image?.drupal_internal__mid
                  )?.uri?.url
                )}
                alt=""
              // style={{ height: '230px', width: '322px' }}
              />
            </a>
            <ul>
              <li>
                <a target="_blank" href={firstSite?.field_link?.url} onClick={() => trackPlaquetteEvent({
                  'event': 'clic_plaquette',
                  'page_name': page_name,
                  'activity': activity,
                  'content_name': firstSite?.field_subtitle,
                  'page_type': page_type,
                  'external_link_url': firstSite?.field_link?.url?.indexOf('.solutions-colas') > 0 ? firstSite?.field_link?.url : '/',
                  'external_link_text': firstSite?.field_link?.url?.indexOf('.solutions-colas') > 0 ? 'site_solutions' : ''
                })}>
                  <div className="listTitle">
                    <ListArrow color="color_yellow" aria-hidden="true" />
                    <h4>{firstSite?.field_subtitle}</h4>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: firstSite?.field_text?.processed,
                    }}
                  />
                </a>
              </li>
            </ul>
          </div>
        )}
        <div className={classNames('listText')}>
          <ul>
            {webSiteList?.map((site, i) => (
              <li key={i}>
                <a target="_blank" href={site?.field_link?.url} onClick={() => trackPlaquetteEvent({
                  'event': 'clic_plaquette',
                  'page_name': page_name,
                  'activity': activity,
                  'content_name': site?.field_subtitle,
                  'page_type': page_type,
                  'external_link_url': site?.field_link?.url?.indexOf('.solutions-colas') > 0 ? site?.field_link?.url : '/',
                  'external_link_text': site?.field_link?.url?.indexOf('.solutions-colas') > 0 ? 'site_solutions' : ''
                })}>
                  <div className="listTitle">
                    <ListArrow color="color_yellow" aria-hidden="true" />
                    <h4>{site?.field_subtitle}</h4>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: site?.field_text?.processed,
                    }}
                  />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WebsiteList;
